@charset "UTF-8";
/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-spacer, .bmpui-ui-selectbox, .bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-ui-label-ad-message, .bmpui-ui-playbacktimelabel, .bmpui-ui-label, .bmpui-ui-volumeslider, .bmpui-ui-seekbar, .bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-ui-button-ad-skip, .bmpui-ui-subtitlesettingsresetbutton, .bmpui-ui-piptogglebutton, .bmpui-ui-airplaytogglebutton, .bmpui-ui-closebutton, .bmpui-ui-replaybutton, .bmpui-ui-hugereplaybutton, .bmpui-ui-clickoverlay, .bmpui-ui-watermark, .bmpui-ui-casttogglebutton, .bmpui-ui-listbox .bmpui-ui-listbox-button, .bmpui-ui-audiotracksettingstogglebutton, .bmpui-ui-subtitlesettingstogglebutton, .bmpui-ui-settingstogglebutton, .bmpui-ui-settingspanelpagebackbutton, .bmpui-ui-settingspanelpageopenbutton, .bmpui-ui-hugeplaybacktogglebutton, .bmpui-ui-volumetogglebutton, .bmpui-ui-vrtogglebutton, .bmpui-ui-fullscreentogglebutton, .bmpui-ui-playbacktogglebutton, .bmpui-ui-button, .bmpui-ui-playbacktoggle-overlay, .bmpui-ui-buffering-overlay, .bmpui-ui-recommendation-overlay, .bmpui-ui-titlebar, .bmpui-ui-errormessage-overlay, .bmpui-ui-cast-status-overlay, .bmpui-ui-volumecontrolbutton, .bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay, .bmpui-ui-seekbar-label, .bmpui-ui-listbox, .bmpui-ui-settings-panel, .bmpui-ui-controlbar, .bmpui-ui-uicontainer, .bmpui-ui-container, .bmpui-ui-component {
  /*! placeholder to avoid removal of empty selector */
  outline: 0;
}

.bmpui-ui-playbacktoggle-overlay, .bmpui-ui-buffering-overlay, .bmpui-ui-recommendation-overlay, .bmpui-ui-titlebar, .bmpui-ui-errormessage-overlay, .bmpui-ui-cast-status-overlay, .bmpui-ui-volumecontrolbutton, .bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay, .bmpui-ui-seekbar-label, .bmpui-ui-listbox, .bmpui-ui-settings-panel, .bmpui-ui-controlbar, .bmpui-ui-uicontainer, .bmpui-ui-container {
  font-size: 1em;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-uicontainer {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 1em;
  overflow: hidden;
  pointer-events: none;
}
.bmpui-ui-uicontainer * {
  pointer-events: auto;
}
.bmpui-ui-uicontainer.bmpui-hidden {
  display: none;
}
.bmpui-ui-uicontainer.bmpui-player-state-playing.bmpui-controls-hidden * {
  cursor: none;
}
.bmpui-ui-uicontainer.bmpui-controls-shown .bmpui-ui-hugeplaybacktogglebutton:focus {
  box-shadow: inset -4px -3px 2px 9px #1b7fcc;
}
.bmpui-ui-uicontainer.bmpui-controls-shown .bmpui-ui-hugeplaybacktogglebutton:focus:not(.bmpui-focus-visible) {
  box-shadow: none;
}
.bmpui-ui-uicontainer.bmpui-no-flexbox {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");
}
.bmpui-ui-uicontainer.bmpui-no-flexbox.bmpui-fullscreen {
  left: 0;
  position: fixed;
  top: 0;
  z-index: 999999;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-controlbar {
  opacity: 1;
  transition: opacity 0.3s, visibility 0s;
  bottom: 0;
  position: absolute;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  box-sizing: border-box;
  line-height: 1em;
  padding: 1em 1em 0.5em;
}
.bmpui-ui-controlbar.bmpui-hidden {
  opacity: 0;
  transition: opacity 0.3s;
}
.bmpui-ui-controlbar .bmpui-controlbar-top > .bmpui-container-wrapper,
.bmpui-ui-controlbar .bmpui-controlbar-bottom > .bmpui-container-wrapper {
  display: flex;
  margin: 0.5em 0;
}
.bmpui-ui-controlbar .bmpui-controlbar-top .bmpui-ui-label {
  font-size: 0.9em;
}
.bmpui-ui-controlbar .bmpui-controlbar-top > .bmpui-container-wrapper > * {
  margin: 0 0.5em;
}
.bmpui-ui-controlbar .bmpui-controlbar-bottom {
  white-space: nowrap;
}
.bmpui-ui-controlbar .bmpui-controlbar-bottom > .bmpui-container-wrapper .bmpui-ui-volumeslider {
  margin: auto 0.5em;
  width: 5em;
}

.bmpui-no-flexbox .bmpui-ui-controlbar .bmpui-controlbar-top > .bmpui-container-wrapper,
.bmpui-no-flexbox .bmpui-ui-controlbar .bmpui-controlbar-bottom > .bmpui-container-wrapper {
  border-spacing: 0.5em 0;
  display: table;
}
.bmpui-no-flexbox .bmpui-ui-controlbar .bmpui-controlbar-top > .bmpui-container-wrapper > *,
.bmpui-no-flexbox .bmpui-ui-controlbar .bmpui-controlbar-bottom > .bmpui-container-wrapper > * {
  display: table-cell;
  vertical-align: middle;
}
.bmpui-no-flexbox .bmpui-ui-controlbar .bmpui-controlbar-top > .bmpui-container-wrapper > *.bmpui-hidden,
.bmpui-no-flexbox .bmpui-ui-controlbar .bmpui-controlbar-bottom > .bmpui-container-wrapper > *.bmpui-hidden {
  display: none;
}
.bmpui-no-flexbox .bmpui-ui-controlbar .bmpui-controlbar-top > .bmpui-container-wrapper .bmpui-ui-volumeslider,
.bmpui-no-flexbox .bmpui-ui-controlbar .bmpui-controlbar-bottom > .bmpui-container-wrapper .bmpui-ui-volumeslider {
  width: 10%;
}

.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-ui-button-ad-skip, .bmpui-ui-subtitlesettingsresetbutton, .bmpui-ui-piptogglebutton, .bmpui-ui-airplaytogglebutton, .bmpui-ui-closebutton, .bmpui-ui-replaybutton, .bmpui-ui-hugereplaybutton, .bmpui-ui-clickoverlay, .bmpui-ui-watermark, .bmpui-ui-casttogglebutton, .bmpui-ui-listbox .bmpui-ui-listbox-button, .bmpui-ui-audiotracksettingstogglebutton, .bmpui-ui-subtitlesettingstogglebutton, .bmpui-ui-settingstogglebutton, .bmpui-ui-settingspanelpagebackbutton, .bmpui-ui-settingspanelpageopenbutton, .bmpui-ui-hugeplaybacktogglebutton, .bmpui-ui-volumetogglebutton, .bmpui-ui-vrtogglebutton, .bmpui-ui-fullscreentogglebutton, .bmpui-ui-playbacktogglebutton, .bmpui-ui-button {
  background-color: transparent;
  background-origin: content-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5em;
  border: 0;
  box-sizing: content-box;
  cursor: pointer;
  font-size: 1em;
  height: 1.5em;
  min-width: 1.5em;
  padding: 0.25em;
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-ui-button-ad-skip .bmpui-label, .bmpui-ui-subtitlesettingsresetbutton .bmpui-label, .bmpui-ui-piptogglebutton .bmpui-label, .bmpui-ui-airplaytogglebutton .bmpui-label, .bmpui-ui-closebutton .bmpui-label, .bmpui-ui-replaybutton .bmpui-label, .bmpui-ui-hugereplaybutton .bmpui-label, .bmpui-ui-clickoverlay .bmpui-label, .bmpui-ui-watermark .bmpui-label, .bmpui-ui-casttogglebutton .bmpui-label, .bmpui-ui-listbox .bmpui-ui-listbox-button .bmpui-label, .bmpui-ui-audiotracksettingstogglebutton .bmpui-label, .bmpui-ui-subtitlesettingstogglebutton .bmpui-label, .bmpui-ui-settingstogglebutton .bmpui-label, .bmpui-ui-settingspanelpagebackbutton .bmpui-label, .bmpui-ui-settingspanelpageopenbutton .bmpui-label, .bmpui-ui-hugeplaybacktogglebutton .bmpui-label, .bmpui-ui-volumetogglebutton .bmpui-label, .bmpui-ui-vrtogglebutton .bmpui-label, .bmpui-ui-fullscreentogglebutton .bmpui-label, .bmpui-ui-playbacktogglebutton .bmpui-label, .bmpui-ui-button .bmpui-label {
  color: #fff;
  display: none;
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-disabled.bmpui-ui-button-ad-skip, .bmpui-disabled.bmpui-ui-subtitlesettingsresetbutton, .bmpui-disabled.bmpui-ui-piptogglebutton, .bmpui-disabled.bmpui-ui-airplaytogglebutton, .bmpui-disabled.bmpui-ui-closebutton, .bmpui-disabled.bmpui-ui-replaybutton, .bmpui-disabled.bmpui-ui-hugereplaybutton, .bmpui-disabled.bmpui-ui-clickoverlay, .bmpui-disabled.bmpui-ui-watermark, .bmpui-disabled.bmpui-ui-casttogglebutton, .bmpui-ui-listbox .bmpui-disabled.bmpui-ui-listbox-button, .bmpui-disabled.bmpui-ui-audiotracksettingstogglebutton, .bmpui-disabled.bmpui-ui-subtitlesettingstogglebutton, .bmpui-disabled.bmpui-ui-settingstogglebutton, .bmpui-disabled.bmpui-ui-settingspanelpagebackbutton, .bmpui-disabled.bmpui-ui-settingspanelpageopenbutton, .bmpui-disabled.bmpui-ui-hugeplaybacktogglebutton, .bmpui-disabled.bmpui-ui-volumetogglebutton, .bmpui-disabled.bmpui-ui-vrtogglebutton, .bmpui-disabled.bmpui-ui-fullscreentogglebutton, .bmpui-disabled.bmpui-ui-playbacktogglebutton, .bmpui-disabled.bmpui-ui-button {
  cursor: default;
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-disabled.bmpui-ui-button-ad-skip, .bmpui-disabled.bmpui-ui-subtitlesettingsresetbutton, .bmpui-disabled.bmpui-ui-piptogglebutton, .bmpui-disabled.bmpui-ui-airplaytogglebutton, .bmpui-disabled.bmpui-ui-closebutton, .bmpui-disabled.bmpui-ui-replaybutton, .bmpui-disabled.bmpui-ui-hugereplaybutton, .bmpui-disabled.bmpui-ui-clickoverlay, .bmpui-disabled.bmpui-ui-watermark, .bmpui-disabled.bmpui-ui-casttogglebutton, .bmpui-ui-listbox .bmpui-disabled.bmpui-ui-listbox-button, .bmpui-disabled.bmpui-ui-audiotracksettingstogglebutton, .bmpui-disabled.bmpui-ui-subtitlesettingstogglebutton, .bmpui-disabled.bmpui-ui-settingstogglebutton, .bmpui-disabled.bmpui-ui-settingspanelpagebackbutton, .bmpui-disabled.bmpui-ui-settingspanelpageopenbutton, .bmpui-disabled.bmpui-ui-hugeplaybacktogglebutton, .bmpui-disabled.bmpui-ui-volumetogglebutton, .bmpui-disabled.bmpui-ui-vrtogglebutton, .bmpui-disabled.bmpui-ui-fullscreentogglebutton, .bmpui-disabled.bmpui-ui-playbacktogglebutton, .bmpui-disabled.bmpui-ui-button,
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-disabled.bmpui-ui-button-ad-skip > *,
.bmpui-disabled.bmpui-ui-subtitlesettingsresetbutton > *,
.bmpui-disabled.bmpui-ui-piptogglebutton > *,
.bmpui-disabled.bmpui-ui-airplaytogglebutton > *,
.bmpui-disabled.bmpui-ui-closebutton > *,
.bmpui-disabled.bmpui-ui-replaybutton > *,
.bmpui-disabled.bmpui-ui-hugereplaybutton > *,
.bmpui-disabled.bmpui-ui-clickoverlay > *,
.bmpui-disabled.bmpui-ui-watermark > *,
.bmpui-disabled.bmpui-ui-casttogglebutton > *,
.bmpui-ui-listbox .bmpui-disabled.bmpui-ui-listbox-button > *,
.bmpui-disabled.bmpui-ui-audiotracksettingstogglebutton > *,
.bmpui-disabled.bmpui-ui-subtitlesettingstogglebutton > *,
.bmpui-disabled.bmpui-ui-settingstogglebutton > *,
.bmpui-disabled.bmpui-ui-settingspanelpagebackbutton > *,
.bmpui-disabled.bmpui-ui-settingspanelpageopenbutton > *,
.bmpui-disabled.bmpui-ui-hugeplaybacktogglebutton > *,
.bmpui-disabled.bmpui-ui-volumetogglebutton > *,
.bmpui-disabled.bmpui-ui-vrtogglebutton > *,
.bmpui-disabled.bmpui-ui-fullscreentogglebutton > *,
.bmpui-disabled.bmpui-ui-playbacktogglebutton > *,
.bmpui-disabled.bmpui-ui-button > * {
  pointer-events: none;
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-disabled.bmpui-ui-button-ad-skip .bmpui-label:hover, .bmpui-disabled.bmpui-ui-subtitlesettingsresetbutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-piptogglebutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-airplaytogglebutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-closebutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-replaybutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-hugereplaybutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-clickoverlay .bmpui-label:hover, .bmpui-disabled.bmpui-ui-watermark .bmpui-label:hover, .bmpui-disabled.bmpui-ui-casttogglebutton .bmpui-label:hover, .bmpui-ui-listbox .bmpui-disabled.bmpui-ui-listbox-button .bmpui-label:hover, .bmpui-disabled.bmpui-ui-audiotracksettingstogglebutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-subtitlesettingstogglebutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-settingstogglebutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-settingspanelpagebackbutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-settingspanelpageopenbutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-hugeplaybacktogglebutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-volumetogglebutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-vrtogglebutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-fullscreentogglebutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-playbacktogglebutton .bmpui-label:hover, .bmpui-disabled.bmpui-ui-button .bmpui-label:hover {
  text-decoration: none;
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-hidden.bmpui-ui-button-ad-skip, .bmpui-hidden.bmpui-ui-subtitlesettingsresetbutton, .bmpui-hidden.bmpui-ui-piptogglebutton, .bmpui-hidden.bmpui-ui-airplaytogglebutton, .bmpui-hidden.bmpui-ui-closebutton, .bmpui-hidden.bmpui-ui-replaybutton, .bmpui-hidden.bmpui-ui-hugereplaybutton, .bmpui-hidden.bmpui-ui-clickoverlay, .bmpui-hidden.bmpui-ui-watermark, .bmpui-hidden.bmpui-ui-casttogglebutton, .bmpui-ui-listbox .bmpui-hidden.bmpui-ui-listbox-button, .bmpui-hidden.bmpui-ui-audiotracksettingstogglebutton, .bmpui-hidden.bmpui-ui-subtitlesettingstogglebutton, .bmpui-hidden.bmpui-ui-settingstogglebutton, .bmpui-hidden.bmpui-ui-settingspanelpagebackbutton, .bmpui-hidden.bmpui-ui-settingspanelpageopenbutton, .bmpui-hidden.bmpui-ui-hugeplaybacktogglebutton, .bmpui-hidden.bmpui-ui-volumetogglebutton, .bmpui-hidden.bmpui-ui-vrtogglebutton, .bmpui-hidden.bmpui-ui-fullscreentogglebutton, .bmpui-hidden.bmpui-ui-playbacktogglebutton, .bmpui-hidden.bmpui-ui-button {
  display: none;
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-ui-button-ad-skip:focus, .bmpui-ui-subtitlesettingsresetbutton:focus, .bmpui-ui-piptogglebutton:focus, .bmpui-ui-airplaytogglebutton:focus, .bmpui-ui-closebutton:focus, .bmpui-ui-replaybutton:focus, .bmpui-ui-hugereplaybutton:focus, .bmpui-ui-clickoverlay:focus, .bmpui-ui-watermark:focus, .bmpui-ui-casttogglebutton:focus, .bmpui-ui-listbox .bmpui-ui-listbox-button:focus, .bmpui-ui-audiotracksettingstogglebutton:focus, .bmpui-ui-subtitlesettingstogglebutton:focus, .bmpui-ui-settingstogglebutton:focus, .bmpui-ui-settingspanelpagebackbutton:focus, .bmpui-ui-settingspanelpageopenbutton:focus, .bmpui-ui-hugeplaybacktogglebutton:focus, .bmpui-ui-volumetogglebutton:focus, .bmpui-ui-vrtogglebutton:focus, .bmpui-ui-fullscreentogglebutton:focus, .bmpui-ui-playbacktogglebutton:focus, .bmpui-ui-button:focus {
  box-shadow: 0 0 0 2px rgba(27, 127, 204, 0.8);
  outline: none;
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-ui-button-ad-skip:focus:not(.bmpui-focus-visible), .bmpui-ui-subtitlesettingsresetbutton:focus:not(.bmpui-focus-visible), .bmpui-ui-piptogglebutton:focus:not(.bmpui-focus-visible), .bmpui-ui-airplaytogglebutton:focus:not(.bmpui-focus-visible), .bmpui-ui-closebutton:focus:not(.bmpui-focus-visible), .bmpui-ui-replaybutton:focus:not(.bmpui-focus-visible), .bmpui-ui-hugereplaybutton:focus:not(.bmpui-focus-visible), .bmpui-ui-clickoverlay:focus:not(.bmpui-focus-visible), .bmpui-ui-watermark:focus:not(.bmpui-focus-visible), .bmpui-ui-casttogglebutton:focus:not(.bmpui-focus-visible), .bmpui-ui-listbox .bmpui-ui-listbox-button:focus:not(.bmpui-focus-visible), .bmpui-ui-audiotracksettingstogglebutton:focus:not(.bmpui-focus-visible), .bmpui-ui-subtitlesettingstogglebutton:focus:not(.bmpui-focus-visible), .bmpui-ui-settingstogglebutton:focus:not(.bmpui-focus-visible), .bmpui-ui-settingspanelpagebackbutton:focus:not(.bmpui-focus-visible), .bmpui-ui-settingspanelpageopenbutton:focus:not(.bmpui-focus-visible), .bmpui-ui-hugeplaybacktogglebutton:focus:not(.bmpui-focus-visible), .bmpui-ui-volumetogglebutton:focus:not(.bmpui-focus-visible), .bmpui-ui-vrtogglebutton:focus:not(.bmpui-focus-visible), .bmpui-ui-fullscreentogglebutton:focus:not(.bmpui-focus-visible), .bmpui-ui-playbacktogglebutton:focus:not(.bmpui-focus-visible), .bmpui-ui-button:focus:not(.bmpui-focus-visible) {
  box-shadow: none;
  outline: none;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-playbacktogglebutton {
  background-image: url("../../assets/skin-modern/images/play.svg");
}
.bmpui-ui-playbacktogglebutton:hover {
  filter: drop-shadow(0 0 1px #fff);
}
.bmpui-ui-playbacktogglebutton.bmpui-on {
  background-image: url("../../assets/skin-modern/images/pause.svg");
}
.bmpui-ui-playbacktogglebutton.bmpui-on.bmpui-stoptoggle {
  background-image: url("../../assets/skin-modern/images/stop.svg");
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-fullscreentogglebutton {
  background-image: url("../../assets/skin-modern/images/fullscreen.svg");
}
.bmpui-ui-fullscreentogglebutton:hover {
  filter: drop-shadow(0 0 1px #fff);
}
.bmpui-ui-fullscreentogglebutton.bmpui-on {
  background-image: url("../../assets/skin-modern/images/fullscreenX.svg");
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-vrtogglebutton {
  background-image: url("../../assets/skin-modern/images/glasses.svg");
}
.bmpui-ui-vrtogglebutton:hover {
  filter: drop-shadow(0 0 1px #fff);
}
.bmpui-ui-vrtogglebutton.bmpui-on {
  background-image: url("../../assets/skin-modern/images/glassesX.svg");
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-volumetogglebutton:hover {
  filter: drop-shadow(0 0 1px #fff);
}
.bmpui-ui-volumetogglebutton.bmpui-muted {
  background-image: url("../../assets/skin-modern/images/music-off.svg");
}
.bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="0"] {
  background-image: url("../../assets/skin-modern/images/music-off.svg");
}
.bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="1"], .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="2"], .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="3"], .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="4"], .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="5"] {
  background-image: url("../../assets/skin-modern/images/music-low.svg");
}
.bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="6"], .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="7"], .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="8"], .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="9"], .bmpui-ui-volumetogglebutton.bmpui-unmuted[data-bmpui-volume-level-tens="10"] {
  background-image: url("../../assets/skin-modern/images/music-on.svg");
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-volumeslider, .bmpui-ui-seekbar {
  cursor: pointer;
  font-size: 1em;
  height: 1em;
  position: relative;
  width: 100%;
}
.bmpui-hidden.bmpui-ui-volumeslider, .bmpui-hidden.bmpui-ui-seekbar {
  display: none;
}
.bmpui-ui-volumeslider:focus, .bmpui-ui-seekbar:focus {
  box-shadow: 0 0 0 2px rgba(27, 127, 204, 0.8);
  outline: none;
}
.bmpui-ui-volumeslider:focus:not(.bmpui-focus-visible), .bmpui-ui-seekbar:focus:not(.bmpui-focus-visible) {
  box-shadow: none;
  outline: none;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-seekbar-marker, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-seekbar-marker, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-markers, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition-marker, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-seekposition, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-seekposition, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-backdrop, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-backdrop {
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  bottom: 0;
  box-sizing: border-box;
  height: 0.3125em;
  left: 0;
  margin: auto;
  position: absolute;
  right: auto;
  top: 0;
  transform-origin: 0 0;
  width: 100%;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-backdrop, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-backdrop {
  background-color: rgba(255, 255, 255, 0.2);
  margin: auto 0;
  width: 100%;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-bufferlevel {
  background-color: #fff;
  margin: auto 0;
  transition: 0.3s linear, 0.3s linear;
  transition-property: transform;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-seekposition, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-seekposition {
  background-color: rgba(255, 255, 255, 0.2);
  margin: auto 0;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition {
  background-color: #1fabe2;
  margin: auto 0;
  transition: 0.1s linear, 0.1s linear;
  transition-property: transform;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition-marker {
  height: 0.9375em;
  left: -0.46875em;
  width: 0.9375em;
  background-color: rgba(31, 171, 226, 0.5);
  border: solid #1fabe2 0.1875em;
  border-radius: 50%;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-markers, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers {
  height: 0.5625em;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-seekbar-marker, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-seekbar-marker {
  background-color: #fff;
  height: 100%;
  text-align: center;
  transition-duration: 1s;
  transition-property: transform;
  transition-timing-function: linear;
  width: 2px;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-seekbar-marker > .bmpui-seekbar-marker-image, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-seekbar-marker > .bmpui-seekbar-marker-image {
  height: 1.25em;
  position: absolute;
  transform: translate(-50%, calc(-100% - 0.2em));
}
.bmpui-seeking.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-seeking.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-bufferlevel,
.bmpui-seeking.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition,
.bmpui-seeking.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition {
  transition: none;
}
.bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar {
  height: 100%;
  width: auto;
}
.bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition, .bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-seekposition, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-seekposition, .bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-backdrop, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-backdrop {
  right: 0;
  top: auto;
}
.bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition .bmpui-seekbar-playbackposition-marker, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition .bmpui-seekbar-playbackposition-marker {
  height: 1em;
  top: -0.5em;
  width: 100%;
}
.bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-backdrop, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-backdrop {
  height: 100%;
  margin: 0 auto;
  width: auto;
}
.bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-bufferlevel {
  margin: 0 auto;
}
.bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-seekposition, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-seekposition {
  margin: 0 auto;
}
.bmpui-ui-watermark {
  background-image: url("../../assets/skin-modern/images/logo.svg");
  background-size: initial;
  height: 4em;
  margin: 2em;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  width: 4em;
}
.bmpui-ui-watermark:hover {
  opacity: 1;
}

.bmpui-ui-hugeplaybacktogglebutton {
  cursor: default;
  height: 8em;
  outline: none;
  overflow: hidden;
  width: 8em;
}
@keyframes bmpui-fade-out {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    transform: scale(2);
    visibility: hidden;
  }
}
@keyframes bmpui-fade-in {
  from {
    opacity: 0;
    transform: scale(2);
    visibility: visible;
  }
  to {
    opacity: 1;
  }
}
@keyframes bmpui-breathe {
  30% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1);
  }
}
.bmpui-ui-hugeplaybacktogglebutton .bmpui-image {
  background-image: url("../../assets/skin-modern/images/play_big.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 7em;
  height: 100%;
  width: 100%;
}
.bmpui-ui-hugeplaybacktogglebutton .bmpui-image:hover {
  animation: bmpui-breathe 3s ease-in-out infinite;
}
.bmpui-ui-hugeplaybacktogglebutton.bmpui-on .bmpui-image {
  animation: bmpui-fade-out 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: visibility 0s 0.3s;
  visibility: hidden;
}
.bmpui-ui-hugeplaybacktogglebutton.bmpui-off .bmpui-image {
  animation: bmpui-fade-in 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  visibility: visible;
}
.bmpui-ui-hugeplaybacktogglebutton.bmpui-no-transition-animations.bmpui-on .bmpui-image, .bmpui-ui-hugeplaybacktogglebutton.bmpui-no-transition-animations.bmpui-off .bmpui-image {
  animation: none;
  transition: none;
}

.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-ui-label-ad-message, .bmpui-ui-playbacktimelabel, .bmpui-ui-label {
  cursor: default;
  white-space: nowrap;
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-hidden.bmpui-ui-label-ad-message, .bmpui-hidden.bmpui-ui-playbacktimelabel, .bmpui-hidden.bmpui-ui-label {
  display: none;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-settings-panel {
  opacity: 1;
  transition: opacity 0.3s, visibility 0s;
  visibility: visible;
  transition: opacity 0.3s, visibility 0s, height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(17, 17, 17, 0.85);
  bottom: 5em;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 2em;
}
.bmpui-hidden.bmpui-ui-settings-panel {
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
}
.bmpui-ui-settings-panel > .bmpui-container-wrapper {
  margin: 0.5em;
  overflow-y: auto;
}
.bmpui-ui-settings-panel > .bmpui-container-wrapper > * {
  margin: 0 0.5em;
}

.bmpui-container-wrapper > .bmpui-ui-settings-panel {
  margin: 0;
}

.bmpui-ui-settings-panel-page {
  display: none;
}
.bmpui-active.bmpui-ui-settings-panel-page {
  display: block;
}
.bmpui-ui-settings-panel-page .bmpui-container-wrapper > *.bmpui-ui-label {
  display: inline-block;
  font-size: 0.8em;
  width: 45%;
}
.bmpui-ui-settings-panel-page .bmpui-container-wrapper > *.bmpui-ui-selectbox {
  margin-left: 10%;
  width: 45%;
}
.bmpui-ui-settings-panel-page .bmpui-ui-settings-panel-item {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
  padding: 0.5em 0;
  white-space: nowrap;
}
.bmpui-ui-settings-panel-page .bmpui-ui-settings-panel-item.bmpui-last {
  border-bottom: 0;
}
.bmpui-ui-settings-panel-page .bmpui-ui-settings-panel-item.bmpui-hidden {
  display: none;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-settingspanelpageopenbutton {
  background-image: url("../../assets/skin-modern/images/settings.svg");
  max-height: 0.8em;
  padding: 0.3em 0;
  vertical-align: bottom;
}
.bmpui-ui-settingspanelpageopenbutton:hover {
  filter: drop-shadow(0 0 1px #fff);
}
.bmpui-on.bmpui-ui-settingspanelpageopenbutton {
  background-image: url("../../assets/skin-modern/images/settingsX.svg");
}

.bmpui-ui-settingspanelpagebackbutton {
  font-size: 0.8em;
  position: relative;
  width: 8em;
}
.bmpui-ui-settingspanelpagebackbutton .bmpui-label {
  display: inline-block;
}
.bmpui-ui-settingspanelpagebackbutton .bmpui-label::before {
  border-bottom: 0.2em solid #fff;
  border-left: 0.2em solid #fff;
  content: "";
  height: 0.6em;
  margin-left: -0.8em;
  position: absolute;
  top: 0.6em;
  transform: rotate(45deg);
  width: 0.6em;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-audiotracksettingstogglebutton:hover, .bmpui-ui-subtitlesettingstogglebutton:hover, .bmpui-ui-settingstogglebutton:hover {
  filter: drop-shadow(0 0 1px #fff);
}
.bmpui-on.bmpui-ui-audiotracksettingstogglebutton:hover, .bmpui-on.bmpui-ui-subtitlesettingstogglebutton:hover, .bmpui-on.bmpui-ui-settingstogglebutton:hover {
  filter: drop-shadow(0 0 1px #1fabe2);
}

.bmpui-ui-settingstogglebutton {
  background-image: url("../../assets/skin-modern/images/settings.svg");
}
.bmpui-ui-settingstogglebutton.bmpui-on {
  background-image: url("../../assets/skin-modern/images/settingsX.svg");
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-selectbox {
  background-color: transparent;
  border: 0;
  color: #1fabe2;
  cursor: pointer;
  font-size: 0.8em;
  padding: 0.3em;
}
.bmpui-ui-selectbox:focus {
  box-shadow: 0 0 0 2px rgba(27, 127, 204, 0.8);
  outline: none;
}
.bmpui-ui-selectbox:focus:not(.bmpui-focus-visible) {
  box-shadow: none;
  outline: none;
}
.bmpui-ui-selectbox option {
  color: #999;
}
.bmpui-ui-selectbox option:checked {
  color: #1fabe2;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-listbox .bmpui-ui-listbox-button {
  box-sizing: border-box;
  display: block;
  font-size: 0.8em;
  height: 100%;
  min-width: 10em;
  padding: 0.5em;
  width: 100%;
}
.bmpui-ui-listbox .bmpui-ui-listbox-button .bmpui-label {
  display: inherit;
}
.bmpui-ui-listbox .bmpui-ui-listbox-button.bmpui-selected {
  background-color: rgba(31, 171, 226, 0.7);
}
.bmpui-ui-listbox .bmpui-ui-listbox-button:hover {
  background-color: rgba(31, 171, 226, 0.85);
}
.bmpui-ui-listbox .bmpui-ui-listbox-button:last-child {
  border-bottom: 0;
}

.bmpui-ui-seekbar-label {
  opacity: 1;
  transition: opacity 0.3s, visibility 0s;
  visibility: visible;
  bottom: 100%;
  left: 0;
  margin-bottom: 1em;
  pointer-events: none;
  position: absolute;
  text-align: center;
}
.bmpui-ui-seekbar-label.bmpui-hidden {
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
}
.bmpui-ui-seekbar-label > .bmpui-container-wrapper {
  margin-left: -50%;
  margin-right: 50%;
  position: relative;
}
.bmpui-ui-seekbar-label .bmpui-seekbar-label-inner {
  border-bottom: 0.2em solid #fff;
}
.bmpui-ui-seekbar-label .bmpui-seekbar-label-inner::after {
  border: solid transparent;
  border-color: transparent;
  border-top-color: #fff;
  border-width: 0.5em;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -0.5em;
  pointer-events: none;
  position: absolute;
  top: 100%;
  width: 0;
}
.bmpui-ui-seekbar-label .bmpui-seekbar-label-inner > .bmpui-container-wrapper {
  position: relative;
}
.bmpui-ui-seekbar-label .bmpui-seekbar-label-inner > .bmpui-container-wrapper .bmpui-seekbar-thumbnail {
  width: 6em;
}
.bmpui-ui-seekbar-label .bmpui-seekbar-label-inner > .bmpui-container-wrapper .bmpui-seekbar-label-metadata {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  bottom: 0;
  box-sizing: border-box;
  display: block;
  padding: 0.5em;
  position: absolute;
  width: 100%;
}
.bmpui-ui-seekbar-label .bmpui-seekbar-label-inner > .bmpui-container-wrapper .bmpui-seekbar-label-metadata .bmpui-seekbar-label-time {
  display: block;
  line-height: 0.8em;
}
.bmpui-ui-seekbar-label .bmpui-seekbar-label-inner > .bmpui-container-wrapper .bmpui-seekbar-label-metadata .bmpui-seekbar-label-title {
  display: block;
  margin-bottom: 0.3em;
  white-space: normal;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-volumeslider, .bmpui-ui-seekbar {
  cursor: pointer;
  font-size: 1em;
  height: 1em;
  position: relative;
  width: 100%;
}
.bmpui-hidden.bmpui-ui-volumeslider, .bmpui-hidden.bmpui-ui-seekbar {
  display: none;
}
.bmpui-ui-volumeslider:focus, .bmpui-ui-seekbar:focus {
  box-shadow: 0 0 0 2px rgba(27, 127, 204, 0.8);
  outline: none;
}
.bmpui-ui-volumeslider:focus:not(.bmpui-focus-visible), .bmpui-ui-seekbar:focus:not(.bmpui-focus-visible) {
  box-shadow: none;
  outline: none;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-backdrop, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-seekposition, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-markers, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-seekbar-marker, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-backdrop, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-seekposition, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition-marker, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-seekbar-marker {
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  bottom: 0;
  box-sizing: border-box;
  height: 0.3125em;
  left: 0;
  margin: auto;
  position: absolute;
  right: auto;
  top: 0;
  transform-origin: 0 0;
  width: 100%;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-backdrop, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-backdrop {
  background-color: rgba(255, 255, 255, 0.2);
  margin: auto 0;
  width: 100%;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-bufferlevel {
  background-color: #fff;
  margin: auto 0;
  transition: 0.3s linear, 0.3s linear;
  transition-property: transform;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-seekposition, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-seekposition {
  background-color: rgba(255, 255, 255, 0.2);
  margin: auto 0;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition {
  background-color: #1fabe2;
  margin: auto 0;
  transition: 0.1s linear, 0.1s linear;
  transition-property: transform;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition-marker {
  height: 0.9375em;
  left: -0.46875em;
  width: 0.9375em;
  background-color: rgba(31, 171, 226, 0.5);
  border: solid #1fabe2 0.1875em;
  border-radius: 50%;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-markers, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers {
  height: 0.5625em;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-seekbar-marker, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-seekbar-marker {
  background-color: #fff;
  height: 100%;
  text-align: center;
  transition-duration: 1s;
  transition-property: transform;
  transition-timing-function: linear;
  width: 2px;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-seekbar-marker > .bmpui-seekbar-marker-image, .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-seekbar-marker > .bmpui-seekbar-marker-image {
  height: 1.25em;
  position: absolute;
  transform: translate(-50%, calc(-100% - 0.2em));
}
.bmpui-seeking.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-seeking.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-bufferlevel,
.bmpui-seeking.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition,
.bmpui-seeking.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition {
  transition: none;
}
.bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar {
  height: 100%;
  width: auto;
}
.bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-backdrop, .bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-seekposition, .bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-backdrop, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-seekposition, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition {
  right: 0;
  top: auto;
}
.bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition .bmpui-seekbar-playbackposition-marker, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition .bmpui-seekbar-playbackposition-marker {
  height: 1em;
  top: -0.5em;
  width: 100%;
}
.bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-backdrop, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-backdrop {
  height: 100%;
  margin: 0 auto;
  width: auto;
}
.bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-bufferlevel {
  margin: 0 auto;
}
.bmpui-vertical.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-seekposition, .bmpui-vertical.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-seekposition {
  margin: 0 auto;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker {
  height: 0.6875em;
  left: -0.34375em;
  width: 0.6875em;
  background-color: #1fabe2;
  border: 0;
}
.bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-bufferlevel {
  display: none;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay {
  bottom: 0;
  font-size: 1.2em;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: bottom 0.15s ease-out;
}
.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay.bmpui-hidden {
  display: none;
}
.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay * {
  all: unset;
}
.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay p {
  display: block;
}
.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay .bmpui-subtitle-region-container {
  position: absolute;
}
.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay .bmpui-subtitle-region-container.bmpui-subtitle-position-default {
  bottom: 2em;
  left: 3em;
  right: 3em;
  top: initial;
}
.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay .bmpui-subtitle-region-container.bmpui-subtitle-position-bottom > div {
  bottom: 0;
  position: absolute;
  width: 100%;
}
.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay .bmpui-ui-subtitle-label {
  text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, -1px 0 0 #000, 1px 0 0 #000, -1px 1px 0 #000, 0 1px 0 #000, 1px 1px 0 #000;
  color: #fff;
  height: fit-content;
}
.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay .bmpui-ui-subtitle-label:nth-child(1n-1)::after {
  content: "\a";
  height: 0;
  white-space: pre-line;
  width: 0;
}
.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay.bmpui-controlbar-visible {
  bottom: 5em;
  transition: bottom 0.15s ease-in;
}

.bmpui-ui-subtitle-overlay.bmpui-cea608 {
  bottom: 2em;
  left: 3em;
  right: 3em;
  top: 2em;
}
.bmpui-ui-subtitle-overlay.bmpui-cea608 .bmpui-subtitle-region-container.bmpui-subtitle-position-default {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.bmpui-ui-subtitle-overlay.bmpui-cea608 .bmpui-ui-subtitle-label {
  font-family: "Courier New", Courier, "Nimbus Mono L", "Cutive Mono", monospace;
  line-height: 1em;
  position: absolute;
  text-transform: uppercase;
}
.bmpui-ui-subtitle-overlay.bmpui-cea608 .bmpui-ui-subtitle-label:nth-child(1n-1)::after {
  content: normal;
  white-space: normal;
}
.bmpui-ui-subtitle-overlay.bmpui-cea608.bmpui-controlbar-visible {
  bottom: 2em;
  transition: none;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-volumecontrolbutton {
  line-height: 0;
  position: relative;
}
.bmpui-ui-volumecontrolbutton .bmpui-ui-volumeslider {
  background-color: #111;
  bottom: 100%;
  height: 6em;
  position: absolute;
  width: 1.5em;
}
.bmpui-ui-volumecontrolbutton .bmpui-ui-volumeslider {
  max-height: 6em;
  transition: max-height 0.15s ease-in;
}
.bmpui-ui-volumecontrolbutton .bmpui-ui-volumeslider.bmpui-hidden {
  max-height: 0;
  transition: max-height 0.15s ease-out;
}
.bmpui-ui-volumecontrolbutton .bmpui-ui-volumeslider .bmpui-seekbar {
  bottom: 0.5em;
  height: auto;
  left: 0.3em;
  overflow: hidden;
  position: absolute;
  right: 0.3em;
  top: 0.5em;
  width: auto;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-casttogglebutton {
  background-image: url("../../assets/skin-modern/images/chromecast.svg");
}
.bmpui-ui-casttogglebutton:hover {
  filter: drop-shadow(0 0 1px #fff);
}
.bmpui-ui-casttogglebutton.bmpui-on {
  background-image: url("../../assets/skin-modern/images/chromecastX.svg");
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-cast-status-overlay {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s, visibility 0s;
  visibility: visible;
  background: #111 url("../../assets/skin-modern/images/chromecast.svg") center no-repeat;
  background-size: 7em 7em;
}
.bmpui-ui-cast-status-overlay.bmpui-hidden {
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
}
.bmpui-ui-cast-status-overlay .bmpui-ui-cast-status-label {
  color: #fff;
  font-size: 1.2em;
  left: 0;
  margin: 0 2em;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 65%;
}
.bmpui-ui-cast-status-overlay .bmpui-ui-cast-status-label * {
  pointer-events: none;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-errormessage-overlay {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #111;
  pointer-events: none;
}
.bmpui-ui-errormessage-overlay.bmpui-hidden {
  display: none;
}
.bmpui-ui-errormessage-overlay .bmpui-ui-errormessage-label {
  color: #fff;
  font-size: 1.2em;
  left: 3em;
  position: absolute;
  right: 3em;
  text-align: center;
  user-select: text;
  white-space: pre-line;
}
.bmpui-ui-errormessage-overlay .bmpui-ui-errormessage-label {
  -ms-transform: translateY(-50%);
  top: 50%;
  transform: translateY(-50%);
}
.bmpui-ui-errormessage-overlay .bmpui-ui-errormessage-label ul {
  color: #999;
  font-size: 0.9em;
  padding: 0;
}
.bmpui-ui-errormessage-overlay .bmpui-ui-errormessage-label ul li {
  list-style: none;
}
.bmpui-ui-errormessage-overlay .bmpui-ui-tvnoisecanvas {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  filter: blur(4px);
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-titlebar {
  opacity: 1;
  transition: opacity 0.3s, visibility 0s;
  position: absolute;
  top: 0;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  box-sizing: border-box;
  padding: 0.5em 1em 1em;
  pointer-events: none;
}
.bmpui-ui-titlebar.bmpui-hidden {
  opacity: 0;
  transition: opacity 0.3s;
}
.bmpui-ui-titlebar > .bmpui-container-wrapper {
  padding: 0.5em;
  pointer-events: none;
}
.bmpui-ui-titlebar > .bmpui-container-wrapper .bmpui-label-metadata {
  pointer-events: none;
}
.bmpui-ui-titlebar > .bmpui-container-wrapper .bmpui-label-metadata-title {
  cursor: default;
  display: block;
  font-size: 1.2em;
  text-shadow: 0 0 5px #000;
  white-space: normal;
}
.bmpui-ui-titlebar > .bmpui-container-wrapper .bmpui-label-metadata-description {
  color: #e6e6e6;
  cursor: default;
  display: block;
  text-shadow: 0 0 5px #000;
  white-space: normal;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-recommendation-overlay {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  display: table;
  opacity: 1;
  transition: opacity 0.6s, visibility 0s;
  visibility: visible;
  background-color: rgba(8, 43, 57, 0.7);
}
.bmpui-ui-recommendation-overlay > .bmpui-container-wrapper {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.bmpui-ui-recommendation-overlay.bmpui-hidden {
  opacity: 0;
  transition: opacity 0.6s, visibility 0.6s;
  visibility: hidden;
}
.bmpui-ui-recommendation-overlay > .bmpui-container-wrapper {
  padding: 3em;
}
.bmpui-ui-recommendation-overlay a {
  color: #fff;
}
.bmpui-ui-recommendation-overlay a:hover, .bmpui-ui-recommendation-overlay a:visited {
  color: #fff;
}
.bmpui-ui-recommendation-overlay.bmpui-recommendations .bmpui-ui-hugereplaybutton {
  bottom: 2em;
  left: 2em;
  position: absolute;
}
.bmpui-ui-recommendation-overlay.bmpui-recommendations .bmpui-ui-recommendation-item {
  background-position: center;
  background-size: cover;
  display: inline-block;
  font-size: 0.7em;
  height: 9em;
  margin: 0.3em 0.6em;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-shadow: 0 0 3px #111;
  transform: scale(1);
  transition: transform 0.15s ease-out;
  width: 16em;
}
.bmpui-ui-recommendation-overlay.bmpui-recommendations .bmpui-ui-recommendation-item .bmpui-background {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  height: 100%;
  position: absolute;
  top: 20%;
  transition: top 0.15s ease-out;
  width: 100%;
}
.bmpui-ui-recommendation-overlay.bmpui-recommendations .bmpui-ui-recommendation-item .bmpui-title {
  bottom: 3em;
  left: 1em;
  position: absolute;
  right: 1em;
}
.bmpui-ui-recommendation-overlay.bmpui-recommendations .bmpui-ui-recommendation-item .bmpui-title .bmpui-innertitle {
  font-size: 1.2em;
  white-space: normal;
  word-break: break-all;
}
.bmpui-ui-recommendation-overlay.bmpui-recommendations .bmpui-ui-recommendation-item .bmpui-duration {
  bottom: 1em;
  left: 1em;
  position: absolute;
}
.bmpui-ui-recommendation-overlay.bmpui-recommendations .bmpui-ui-recommendation-item:hover {
  outline: 2px solid #1fabe2;
  transform: scale(1.05);
  transition: transform 0.15s ease-in;
}
.bmpui-ui-recommendation-overlay.bmpui-recommendations .bmpui-ui-recommendation-item:hover .bmpui-background {
  top: 0;
  transition: top 0.15s ease-in;
}

.bmpui-ui-clickoverlay {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-hugereplaybutton {
  height: 5em;
  outline: none;
  width: 5em;
}
.bmpui-ui-hugereplaybutton .bmpui-image {
  background-image: url("../../assets/skin-modern/images/replayX.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5em;
  height: 100%;
  width: 100%;
}
@keyframes bmpui-spin {
  50% {
    transform: rotate(180deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
.bmpui-ui-hugereplaybutton .bmpui-image:hover {
  animation: bmpui-spin 0.5s ease-in;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-replaybutton {
  background-image: url("../../assets/skin-modern/images/replay-nocircle.svg");
}
.bmpui-ui-replaybutton:hover {
  filter: drop-shadow(0 0 1px #fff);
}

.bmpui-ui-playbacktimelabel {
  text-transform: uppercase;
}
.bmpui-ui-playbacktimelabel.bmpui-ui-playbacktimelabel-live {
  cursor: pointer;
}
.bmpui-ui-playbacktimelabel.bmpui-ui-playbacktimelabel-live::before {
  color: #999;
  content: "●";
  padding-right: 0.2em;
}
.bmpui-ui-playbacktimelabel.bmpui-ui-playbacktimelabel-live.bmpui-ui-playbacktimelabel-live-edge::before {
  color: #f00;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-buffering-overlay {
  display: table;
}
.bmpui-ui-buffering-overlay > .bmpui-container-wrapper {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.bmpui-ui-buffering-overlay {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.6s, visibility 0s;
  visibility: visible;
  background-color: rgba(8, 43, 57, 0.7);
}
.bmpui-ui-buffering-overlay.bmpui-hidden {
  opacity: 0;
  transition: opacity 0.6s, visibility 0.6s;
  visibility: hidden;
}
.bmpui-ui-buffering-overlay > .bmpui-container-wrapper {
  padding: 3em;
}
.bmpui-ui-buffering-overlay a {
  color: #fff;
}
.bmpui-ui-buffering-overlay a:hover, .bmpui-ui-buffering-overlay a:visited {
  color: #fff;
}
.bmpui-ui-buffering-overlay .bmpui-ui-buffering-overlay-indicator {
  animation: bmpui-fancy 2s ease-in infinite;
  background: url("../../assets/skin-modern/images/loader.svg") no-repeat center;
  display: inline-block;
  height: 2em;
  margin: 0.2em;
  width: 2em;
}
@keyframes bmpui-fancy {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale(2);
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}
.bmpui-ui-buffering-overlay .bmpui-ui-buffering-overlay-indicator:nth-child(1) {
  animation-delay: 0s;
}
.bmpui-ui-buffering-overlay .bmpui-ui-buffering-overlay-indicator:nth-child(2) {
  animation-delay: 0.2s;
}
.bmpui-ui-buffering-overlay .bmpui-ui-buffering-overlay-indicator:nth-child(3) {
  animation-delay: 0.4s;
}
.bmpui-ui-buffering-overlay.bmpui-hidden .bmpui-ui-buffering-overlay-indicator {
  display: none;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-playbacktoggle-overlay .bmpui-ui-hugeplaybacktogglebutton {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-closebutton {
  background-image: url("../../assets/skin-modern/images/close.svg");
}
@keyframes bmpui-pulsate {
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(1);
  }
}
.bmpui-ui-closebutton:hover {
  filter: drop-shadow(0 0 1px #fff);
  animation: bmpui-pulsate 1s;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-airplaytogglebutton {
  background-image: url("../../assets/skin-modern/images/airplay.svg");
}
.bmpui-ui-airplaytogglebutton:hover {
  filter: drop-shadow(0 0 1px #fff);
}
.bmpui-ui-airplaytogglebutton.bmpui-on {
  background-image: url("../../assets/skin-modern/images/airplayX.svg");
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-piptogglebutton {
  background-image: url("../../assets/skin-modern/images/picinpic1.svg");
}
.bmpui-ui-piptogglebutton:hover {
  filter: drop-shadow(0 0 1px #fff);
}
.bmpui-ui-piptogglebutton.bmpui-on {
  background-image: url("../../assets/skin-modern/images/picinpic1X.svg");
}

.bmpui-ui-spacer {
  height: 100%;
  width: 100%;
}

.bmpui-ui-subtitlesettingsresetbutton {
  font-size: 0.8em;
  width: 12em;
}
.bmpui-ui-subtitlesettingsresetbutton .bmpui-label {
  display: inline-block;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-white100 .bmpui-ui-subtitle-label {
  color: white;
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-white75 .bmpui-ui-subtitle-label {
  color: rgba(255, 255, 255, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-white50 .bmpui-ui-subtitle-label {
  color: rgba(255, 255, 255, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-white25 .bmpui-ui-subtitle-label {
  color: rgba(255, 255, 255, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-black100 .bmpui-ui-subtitle-label {
  color: black;
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-black75 .bmpui-ui-subtitle-label {
  color: rgba(0, 0, 0, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-black50 .bmpui-ui-subtitle-label {
  color: rgba(0, 0, 0, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-black25 .bmpui-ui-subtitle-label {
  color: rgba(0, 0, 0, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-red100 .bmpui-ui-subtitle-label {
  color: red;
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-red75 .bmpui-ui-subtitle-label {
  color: rgba(255, 0, 0, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-red50 .bmpui-ui-subtitle-label {
  color: rgba(255, 0, 0, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-red25 .bmpui-ui-subtitle-label {
  color: rgba(255, 0, 0, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-green100 .bmpui-ui-subtitle-label {
  color: lime;
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-green75 .bmpui-ui-subtitle-label {
  color: rgba(0, 255, 0, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-green50 .bmpui-ui-subtitle-label {
  color: rgba(0, 255, 0, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-green25 .bmpui-ui-subtitle-label {
  color: rgba(0, 255, 0, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-blue100 .bmpui-ui-subtitle-label {
  color: blue;
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-blue75 .bmpui-ui-subtitle-label {
  color: rgba(0, 0, 255, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-blue50 .bmpui-ui-subtitle-label {
  color: rgba(0, 0, 255, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-blue25 .bmpui-ui-subtitle-label {
  color: rgba(0, 0, 255, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-cyan100 .bmpui-ui-subtitle-label {
  color: aqua;
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-cyan75 .bmpui-ui-subtitle-label {
  color: rgba(0, 255, 255, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-cyan50 .bmpui-ui-subtitle-label {
  color: rgba(0, 255, 255, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-cyan25 .bmpui-ui-subtitle-label {
  color: rgba(0, 255, 255, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-yellow100 .bmpui-ui-subtitle-label {
  color: yellow;
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-yellow75 .bmpui-ui-subtitle-label {
  color: rgba(255, 255, 0, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-yellow50 .bmpui-ui-subtitle-label {
  color: rgba(255, 255, 0, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-yellow25 .bmpui-ui-subtitle-label {
  color: rgba(255, 255, 0, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-magenta100 .bmpui-ui-subtitle-label {
  color: fuchsia;
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-magenta75 .bmpui-ui-subtitle-label {
  color: rgba(255, 0, 255, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-magenta50 .bmpui-ui-subtitle-label {
  color: rgba(255, 0, 255, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-fontcolor-magenta25 .bmpui-ui-subtitle-label {
  color: rgba(255, 0, 255, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-white100 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: white;
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-white75 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(255, 255, 255, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-white50 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(255, 255, 255, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-white25 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(255, 255, 255, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-black100 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: black;
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-black75 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(0, 0, 0, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-black50 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(0, 0, 0, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-black25 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(0, 0, 0, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-red100 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: red;
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-red75 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(255, 0, 0, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-red50 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(255, 0, 0, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-red25 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(255, 0, 0, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-green100 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: lime;
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-green75 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(0, 255, 0, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-green50 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(0, 255, 0, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-green25 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(0, 255, 0, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-blue100 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: blue;
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-blue75 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(0, 0, 255, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-blue50 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(0, 0, 255, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-blue25 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(0, 0, 255, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-cyan100 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: aqua;
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-cyan75 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(0, 255, 255, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-cyan50 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(0, 255, 255, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-cyan25 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(0, 255, 255, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-yellow100 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: yellow;
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-yellow75 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(255, 255, 0, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-yellow50 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(255, 255, 0, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-yellow25 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(255, 255, 0, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-magenta100 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: fuchsia;
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-magenta75 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(255, 0, 255, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-magenta50 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(255, 0, 255, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-bgcolor-magenta25 .bmpui-subtitle-region-container .bmpui-ui-subtitle-label {
  background-color: rgba(255, 0, 255, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-white100 .bmpui-subtitle-region-container {
  background-color: white;
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-white75 .bmpui-subtitle-region-container {
  background-color: rgba(255, 255, 255, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-white50 .bmpui-subtitle-region-container {
  background-color: rgba(255, 255, 255, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-white25 .bmpui-subtitle-region-container {
  background-color: rgba(255, 255, 255, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-black100 .bmpui-subtitle-region-container {
  background-color: black;
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-black75 .bmpui-subtitle-region-container {
  background-color: rgba(0, 0, 0, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-black50 .bmpui-subtitle-region-container {
  background-color: rgba(0, 0, 0, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-black25 .bmpui-subtitle-region-container {
  background-color: rgba(0, 0, 0, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-red100 .bmpui-subtitle-region-container {
  background-color: red;
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-red75 .bmpui-subtitle-region-container {
  background-color: rgba(255, 0, 0, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-red50 .bmpui-subtitle-region-container {
  background-color: rgba(255, 0, 0, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-red25 .bmpui-subtitle-region-container {
  background-color: rgba(255, 0, 0, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-green100 .bmpui-subtitle-region-container {
  background-color: lime;
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-green75 .bmpui-subtitle-region-container {
  background-color: rgba(0, 255, 0, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-green50 .bmpui-subtitle-region-container {
  background-color: rgba(0, 255, 0, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-green25 .bmpui-subtitle-region-container {
  background-color: rgba(0, 255, 0, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-blue100 .bmpui-subtitle-region-container {
  background-color: blue;
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-blue75 .bmpui-subtitle-region-container {
  background-color: rgba(0, 0, 255, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-blue50 .bmpui-subtitle-region-container {
  background-color: rgba(0, 0, 255, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-blue25 .bmpui-subtitle-region-container {
  background-color: rgba(0, 0, 255, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-cyan100 .bmpui-subtitle-region-container {
  background-color: aqua;
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-cyan75 .bmpui-subtitle-region-container {
  background-color: rgba(0, 255, 255, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-cyan50 .bmpui-subtitle-region-container {
  background-color: rgba(0, 255, 255, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-cyan25 .bmpui-subtitle-region-container {
  background-color: rgba(0, 255, 255, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-yellow100 .bmpui-subtitle-region-container {
  background-color: yellow;
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-yellow75 .bmpui-subtitle-region-container {
  background-color: rgba(255, 255, 0, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-yellow50 .bmpui-subtitle-region-container {
  background-color: rgba(255, 255, 0, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-yellow25 .bmpui-subtitle-region-container {
  background-color: rgba(255, 255, 0, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-magenta100 .bmpui-subtitle-region-container {
  background-color: fuchsia;
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-magenta75 .bmpui-subtitle-region-container {
  background-color: rgba(255, 0, 255, 0.75);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-magenta50 .bmpui-subtitle-region-container {
  background-color: rgba(255, 0, 255, 0.5);
}
.bmpui-ui-subtitle-overlay.bmpui-windowcolor-magenta25 .bmpui-subtitle-region-container {
  background-color: rgba(255, 0, 255, 0.25);
}
.bmpui-ui-subtitle-overlay.bmpui-fontsize-50 .bmpui-ui-subtitle-label {
  font-size: 0.5em;
}
.bmpui-ui-subtitle-overlay.bmpui-fontsize-75 .bmpui-ui-subtitle-label {
  font-size: 0.75em;
}
.bmpui-ui-subtitle-overlay.bmpui-fontsize-100 .bmpui-ui-subtitle-label {
  font-size: 1em;
}
.bmpui-ui-subtitle-overlay.bmpui-fontsize-150 .bmpui-ui-subtitle-label {
  font-size: 1.5em;
}
.bmpui-ui-subtitle-overlay.bmpui-fontsize-200 .bmpui-ui-subtitle-label {
  font-size: 2em;
}
.bmpui-ui-subtitle-overlay.bmpui-fontsize-300 .bmpui-ui-subtitle-label {
  font-size: 3em;
}
.bmpui-ui-subtitle-overlay.bmpui-fontsize-400 .bmpui-ui-subtitle-label {
  font-size: 4em;
}
.bmpui-ui-subtitle-overlay.bmpui-characteredge-none .bmpui-ui-subtitle-label {
  text-shadow: none;
}
.bmpui-ui-subtitle-overlay.bmpui-characteredge-raised .bmpui-ui-subtitle-label {
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.9), 0px 1px 4px rgba(0, 0, 0, 0.9), 0px 2px 4px rgba(0, 0, 0, 0.9);
}
.bmpui-ui-subtitle-overlay.bmpui-characteredge-depressed .bmpui-ui-subtitle-label {
  text-shadow: rgba(0, 0, 0, 0.8) 0px -2px 1px;
}
.bmpui-ui-subtitle-overlay.bmpui-characteredge-uniform .bmpui-ui-subtitle-label {
  text-shadow: -2px 0px 1px rgba(0, 0, 0, 0.8), 2px 0px 1px rgba(0, 0, 0, 0.8), 0px -2px 1px rgba(0, 0, 0, 0.8), 0px 2px 1px rgba(0, 0, 0, 0.8), -1px 1px 1px rgba(0, 0, 0, 0.8), 1px 1px 1px rgba(0, 0, 0, 0.8), 1px -1px 1px rgba(0, 0, 0, 0.8), 1px 1px 1px rgba(0, 0, 0, 0.8);
}
.bmpui-ui-subtitle-overlay.bmpui-characteredge-dropshadowed .bmpui-ui-subtitle-label {
  text-shadow:  0px 2px 1px rgba(0, 0, 0, 0.8);
}
.bmpui-ui-subtitle-overlay.bmpui-fontfamily-monospacedserif .bmpui-ui-subtitle-label {
  font-family: "Courier New", Courier, "Nimbus Mono L", "Cutive Mono", monospace;
}
.bmpui-ui-subtitle-overlay.bmpui-fontfamily-proportionalserif .bmpui-ui-subtitle-label {
  font-family: "Times New Roman", Times, Georgia, Cambria, "PT Serif Caption", serif;
}
.bmpui-ui-subtitle-overlay.bmpui-fontfamily-monospacedsansserif .bmpui-ui-subtitle-label {
  font-family: "Deja Vu Sans Mono", "Lucida Console", Monaco, Consolas, "PT Mono", monospace;
}
.bmpui-ui-subtitle-overlay.bmpui-fontfamily-proportionalsansserif .bmpui-ui-subtitle-label {
  font-family: Roboto, "Arial Unicode Ms", Arial, Helvetica, Verdana, "PT Sans Caption", sans-serif;
}
.bmpui-ui-subtitle-overlay.bmpui-fontfamily-casual .bmpui-ui-subtitle-label {
  font-family: "Comic Sans MS", Impact, Handlee, fantasy;
}
.bmpui-ui-subtitle-overlay.bmpui-fontfamily-cursive .bmpui-ui-subtitle-label {
  font-family: "Monotype Corsiva", "URW Chancery L", "Apple Chancery", "Dancing Script", cursive;
  font-style: italic;
}
.bmpui-ui-subtitle-overlay.bmpui-fontfamily-smallcapital .bmpui-ui-subtitle-label {
  font-variant: small-caps;
}

.bmpui-ui-subtitlesettingstogglebutton {
  background-image: url("../../assets/skin-modern/images/subtitles.svg");
}
.bmpui-ui-subtitlesettingstogglebutton.bmpui-on {
  background-image: url("../../assets/skin-modern/images/subtitlesX.svg");
}

.bmpui-ui-audiotracksettingstogglebutton {
  background-image: url("../../assets/skin-modern/images/audio-tracks.svg");
}
.bmpui-ui-audiotracksettingstogglebutton.bmpui-on {
  background-image: url("../../assets/skin-modern/images/audio-tracksX.svg");
}

.bmpui-ui-skin-ads {
  /* Hide the huge playback button overlay while an ad is playing, so a click goes
   * through to the click-through overlay which will register the click and then
   * pause playback. In the paused state, the huge playback toggle button will be
   * shown and continues playback of the ad when clicked.
   */
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status {
  background-color: rgba(0, 0, 0, 0.7);
  left: 1.5em;
  padding: 0.5em 1.5em;
  position: absolute;
  top: 1em;
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-ui-label-ad-message {
  color: #999;
  white-space: normal;
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-ui-button-ad-skip .bmpui-label {
  display: inherit;
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-ui-button-ad-skip .bmpui-label:hover {
  text-decoration: underline;
}
.bmpui-ui-skin-ads .bmpui-ui-ads-status .bmpui-ui-button-ad-skip::before {
  color: #1fabe2;
  content: "●";
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.bmpui-ui-skin-ads.bmpui-player-state-playing .bmpui-ui-playbacktoggle-overlay {
  display: none;
}
.bmpui-ui-skin-ads.bmpui-ui-skin-smallscreen .bmpui-ui-ads-status {
  bottom: 0;
  left: 0;
  padding: 1em 1.5em;
  top: auto;
  width: 100%;
}

.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-titlebar, .bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-controlbar {
  background: rgba(17, 17, 17, 0.85);
  padding: 0.3em 0.5em;
}
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-controlbar {
  bottom: 10%;
  left: 10%;
  right: 10%;
  width: auto;
}
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-titlebar {
  left: 10%;
  right: 10%;
  top: 10%;
  width: auto;
}
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-watermark {
  margin: 0;
  right: 10%;
  top: 10%;
}
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay {
  bottom: 10%;
}
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay.bmpui-controlbar-visible {
  bottom: 20%;
}
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay .bmpui-ui-subtitle-label {
  background-color: rgba(0, 0, 0, 0.5);
  box-decoration-break: clone;
  display: inline-flex;
  line-height: 2em;
  padding: 0.3em 0.5em;
  text-shadow: none;
}
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-hugeplaybacktogglebutton .bmpui-image {
  background-image: url("../../assets/skin-modern/images/pause.svg");
  opacity: 0.7;
}
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-hugeplaybacktogglebutton.bmpui-on .bmpui-image, .bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-hugeplaybacktogglebutton.bmpui-off .bmpui-image {
  animation: none;
  transition: none;
}
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-seekbar-bufferlevel,
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-seekbar-playbackposition,
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-buffering-overlay,
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-titlebar,
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-controlbar,
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-subtitle-overlay {
  transition: none;
}
.bmpui-ui-skin-cast-receiver.bmpui-ui-uicontainer .bmpui-ui-buffering-overlay-indicator {
  display: none;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-skin-smallscreen {
  font-size: 1.2em;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-settings-panel {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  display: table;
  display: flex;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-settings-panel > .bmpui-container-wrapper {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-settings-panel .bmpui-ui-settings-panel-page {
  min-width: 15em;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-settings-panel > .bmpui-container-wrapper {
  margin: auto;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-settings-panel .bmpui-ui-settings-panel-item {
  text-align: left;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-settings-panel .bmpui-ui-closebutton {
  margin: 2em;
  position: absolute;
  right: 0;
  top: 0;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-watermark {
  display: none;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-titlebar > .bmpui-container-wrapper {
  display: flex;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-titlebar > .bmpui-container-wrapper .bmpui-ui-label {
  align-items: center;
  display: inline-flex;
  font-size: 1em;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-titlebar > .bmpui-container-wrapper .bmpui-label-metadata-title {
  width: 100%;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-cast-status-overlay {
  background: #111;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-cast-status-overlay .bmpui-ui-cast-status-label {
  top: 30%;
}
.bmpui-ui-skin-smallscreen .bmpui-ui-hugeplaybacktogglebutton .bmpui-image {
  background-image: url("../../assets/skin-modern/images/play.svg");
  background-size: 4em;
}
.bmpui-ui-skin-smallscreen.bmpui-remote-control .bmpui-ui-hugeplaybacktogglebutton.bmpui-on .bmpui-image {
  animation: none;
  background-image: url("../../assets/skin-modern/images/pause.svg");
  visibility: visible;
}
.bmpui-ui-skin-smallscreen.bmpui-remote-control .bmpui-ui-hugeplaybacktogglebutton.bmpui-off .bmpui-image {
  animation: none;
}
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-400 .bmpui-ui-settings-panel,
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-400 .bmpui-ui-hugeplaybacktogglebutton,
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-400 .bmpui-ui-hugereplaybutton,
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-400 .bmpui-ui-errormessage-overlay,
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-400 .bmpui-ui-buffering-overlay,
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-400 .bmpui-ui-subtitle-overlay,
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-400 .bmpui-ui-cast-status-overlay {
  font-size: 0.6em;
}
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-600 .bmpui-ui-settings-panel,
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-600 .bmpui-ui-hugeplaybacktogglebutton,
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-600 .bmpui-ui-hugereplaybutton,
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-600 .bmpui-ui-errormessage-overlay,
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-600 .bmpui-ui-buffering-overlay,
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-600 .bmpui-ui-subtitle-overlay,
.bmpui-ui-skin-smallscreen.bmpui-layout-max-width-600 .bmpui-ui-cast-status-overlay {
  font-size: 0.8em;
}
.bmpui-ui-skin-smallscreen.bmpui-no-flexbox .bmpui-ui-titlebar > .bmpui-container-wrapper {
  border-spacing: 0.5em 0;
  display: table;
  width: 100%;
}
.bmpui-ui-skin-smallscreen.bmpui-no-flexbox .bmpui-ui-titlebar > .bmpui-container-wrapper > * {
  display: table-cell;
  vertical-align: middle;
  width: 1em;
}
.bmpui-ui-skin-smallscreen.bmpui-no-flexbox .bmpui-ui-titlebar > .bmpui-container-wrapper > *.bmpui-hidden {
  display: none;
}
.bmpui-ui-skin-smallscreen.bmpui-no-flexbox .bmpui-ui-titlebar > .bmpui-container-wrapper .bmpui-label-metadata-title {
  width: 100%;
}

/*
 * this will use the existing `hidden-animated` mixin and add additional transitions properties
 *
 * Example:
 * @include hidden-animated-with-additional-transitions($animation-duration,
 *         (
 *                 height: (.35s, cubic-bezier(.4, 0, .2, 1)),
 *                 width: (.35s, cubic-bezier(.4, 0, .2, 1))
 *         )
 * );
 */
.bmpui-ui-skin-tv {
  font-size: 2vh;
}
.bmpui-ui-skin-tv .bmpui-ui-titlebar > .bmpui-container-wrapper {
  margin: 2% 2% 2vh;
  width: 96%;
}
.bmpui-ui-skin-tv .bmpui-ui-titlebar-top {
  margin-bottom: 1vh;
}
.bmpui-ui-skin-tv .bmpui-ui-titlebar-top > .bmpui-container-wrapper {
  align-items: center;
  display: inline-flex;
  font-size: 3.5vh;
  justify-content: flex-end;
  width: 100%;
}
.bmpui-ui-skin-tv .bmpui-ui-titlebar-top > .bmpui-container-wrapper .bmpui-label-metadata-title {
  font-size: 3.5vh;
  margin-right: auto;
}
.bmpui-ui-skin-tv .bmpui-ui-hugeplaybacktogglebutton > .bmpui-image {
  background-size: 20vh;
}
.bmpui-ui-skin-tv .bmpui-ui-titlebar-bottom > .bmpui-container-wrapper {
  align-items: flex-start;
  display: inline-flex;
  font-size: 3.5vh;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.bmpui-ui-skin-tv .bmpui-ui-titlebar-bottom .bmpui-label-metadata-description {
  font-size: 2vh;
  line-height: 2em;
  width: 66%;
}
.bmpui-ui-skin-tv .bmpui-ui-titlebar-bottom .bmpui-ui-settings-panel {
  font-size: 3vh;
  right: 0;
  top: 0;
}
.bmpui-ui-skin-tv .bmpui-ui-subtitlesettingstogglebutton,
.bmpui-ui-skin-tv .bmpui-ui-audiotracksettingstogglebutton {
  font-size: 5vh;
}
.bmpui-ui-skin-tv .bmpui-ui-controlbar {
  font-size: 3vh;
}
.bmpui-ui-skin-tv .bmpui-ui-controlbar > .bmpui-container-wrapper {
  margin: 2% 0;
  width: 99%;
}
.bmpui-ui-skin-tv .bmpui-ui-playbacktimelabel {
  font-size: 2.5vh;
}
.bmpui-ui-skin-tv :focus {
  border: 0;
  box-shadow: 0 0 0 2px rgba(27, 127, 204, 0.8);
  filter: drop-shadow(0 0 0.3em #1fabe2);
  outline: none;
  transition: all 0.05s ease-in-out;
}

.bmpui-ui-uicontainer {
  color: #fff;
  font-family: sans-serif;
  font-size: 1em;
  text-align: left;
  user-select: none;
}
.bmpui-ui-uicontainer.bmpui-player-state-idle .bmpui-ui-controlbar,
.bmpui-ui-uicontainer.bmpui-player-state-idle .bmpui-ui-titlebar,
.bmpui-ui-uicontainer.bmpui-player-state-idle .bmpui-ui-hugeplaybacktogglebutton {
  display: none;
}
.bmpui-ui-uicontainer.bmpui-player-state-finished .bmpui-ui-controlbar,
.bmpui-ui-uicontainer.bmpui-player-state-finished .bmpui-ui-hugeplaybacktogglebutton {
  display: none;
}
.bmpui-ui-uicontainer .bmpui-text-right {
  text-align: right;
}
.bmpui-ui-uicontainer.bmpui-layout-max-width-400 .bmpui-ui-titlebar .bmpui-label-metadata-description {
  display: none;
}
.bmpui-ui-uicontainer.bmpui-layout-max-width-400 .bmpui-ui-hugeplaybacktogglebutton, .bmpui-ui-uicontainer.bmpui-layout-max-width-600 .bmpui-ui-hugeplaybacktogglebutton {
  font-size: 0.7em;
}
.bmpui-ui-uicontainer.bmpui-layout-max-width-400 .bmpui-ui-watermark, .bmpui-ui-uicontainer.bmpui-layout-max-width-600 .bmpui-ui-watermark {
  font-size: 0.7em;
}