@import '../variables';
@import '../mixins';

.#{$prefix}-ui-closebutton {
  @extend %ui-button;

  @keyframes #{$prefix}-pulsate {
    20% {
      transform: scale(1.1);
    }

    40% {
      transform: scale(1);
    }

    60% {
      transform: scale(1.1);
    }

    80% {
      transform: scale(1);
    }
  }

  background-image: url('../../assets/skin-modern/images/close.svg');

  &:hover {
    @include svg-icon-shadow;

    animation: #{$prefix}-pulsate 1s;
  }
}
