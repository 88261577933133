@import '../variables';
@import '../mixins';

%ui-settingstogglebutton {
  @extend %ui-button;

  &:hover {
    @include svg-icon-shadow;
  }

  &.#{$prefix}-on {
    &:hover {
      @include svg-icon-on-shadow;
    }
  }
}

.#{$prefix}-ui-settingstogglebutton {
  @extend %ui-settingstogglebutton;

  background-image: url('../../assets/skin-modern/images/settings.svg');

  &.#{$prefix}-on {
    background-image: url('../../assets/skin-modern/images/settingsX.svg');
  }
}
