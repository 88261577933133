@import '../variables';

.#{$prefix}-ui-hugeplaybacktogglebutton {
  @extend %ui-button;

  @keyframes #{$prefix}-fade-out {
    from {
      opacity: 1;
      visibility: visible;
    }

    to {
      opacity: 0;
      transform: scale(2);
      visibility: hidden;
    }
  }

  @keyframes #{$prefix}-fade-in {
    from {
      opacity: 0;
      transform: scale(2);
      visibility: visible;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes #{$prefix}-breathe {
    30% {
      transform: scale(1.1);
    }

    60% {
      transform: scale(1);
    }
  }

  cursor: default;
  height: 8em;
  outline: none;
  overflow: hidden; // hide overflow from scale animation
  width: 8em;

  .#{$prefix}-image {
    background-image: url('../../assets/skin-modern/images/play_big.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 7em;
    height: 100%;
    width: 100%;

    &:hover {
      animation:  #{$prefix}-breathe 3s ease-in-out infinite;
    }
  }

  &.#{$prefix}-on {
    .#{$prefix}-image {
      animation: #{$prefix}-fade-out $animation-duration cubic-bezier(.55, .055, .675, .19); // http://easings.net/de#easeInCubic
      transition: visibility 0s $animation-duration;
      visibility: hidden;
    }
  }

  &.#{$prefix}-off {
    .#{$prefix}-image {
      animation:  #{$prefix}-fade-in $animation-duration cubic-bezier(.55, .055, .675, .19); // http://easings.net/de#easeInCubic
      visibility: visible;
    }
  }

  &.#{$prefix}-no-transition-animations {
    &.#{$prefix}-on,
    &.#{$prefix}-off {
      .#{$prefix}-image {
        animation: none;
        transition: none;
      }
    }
  }
}
